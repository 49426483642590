import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

export const axiosBaseConfigOptions: AxiosRequestConfig = {
  timeout: 30000,
  headers: {
    'x-version': 1,
    'x-platform': process.env.APP_PLATFORM
  }
};

export const axiosInterceptorRequest = async (headerConfig: AxiosRequestConfig) => {
  const access_token = Cookies.get('access_token') ?? (await localStorage.getItem('access_token'));
  headerConfig.headers.Authorization = `Bearer ${access_token}`;

  return headerConfig;
};

export const axiosInterceptorResponseError = async (error: AxiosError) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const status = error.response ? error.response.status : null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const errorConfig = error.config;

  // TODO: logic for handle 406 or 401

  return Promise.reject(error);
};

export const instance = Axios.create({
  baseURL: process.env.APP_AUTH_SERVICE_URL,
  ...axiosBaseConfigOptions
});

instance.interceptors.request.use(axiosInterceptorRequest);

instance.interceptors.response.use((response) => response, axiosInterceptorResponseError);
