export { default as EnglishCommon } from './common.json';
export { default as EnglishCooperative } from './cooperative.json';
export { default as EnglishDashboard } from './dashboard.json';
export { default as EnglishInvoice } from './invoice.json';
export { default as EnglishInvoiceDetail } from './invoiceDetail.json';
export { default as EnglishLoginPage } from './login.json';
export { default as EnglishMember } from './member.json';
export { default as EnglishMemberDetail } from './memberDetail.json';
export { default as EnglishMenu } from './menu.json';
export { default as EnglishOnboarding } from './onboarding.json';
export { default as EnglishPayroll } from './payroll.json';
