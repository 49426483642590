import { useSearchParams } from 'next/navigation';
import { useCallback } from 'react';

function useSetSearchParams() {
  const searchParams = useSearchParams();

  const setSearchParams = useCallback((name: string, value: string) => {
    const url = new URL(window.location.toString());
    url.searchParams.set(name, value);
    window.history.replaceState({}, '', url);
  }, []);

  const createQueryParams = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams);
      params.set(name, value);

      return params.toString();
    },
    [searchParams]
  );

  const retainQueryParams = (link: string) => {
    return `${link}?cId=${searchParams.get('cId')}`;
  };

  return { searchParams, setSearchParams, createQueryParams, retainQueryParams };
}

export default useSetSearchParams;
