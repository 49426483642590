import { axiosAuthInstance } from '@core/axios/axios.authConfig';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

interface PostBodyChangeLanguageInterface {
  lang: string;
}

export const postChangeLanguage = async (
  body: PostBodyChangeLanguageInterface
): Promise<APIResponse<null>> => {
  const { data } = await axiosAuthInstance.post(`/users/language?lang=${body.lang}`);

  return data;
};

function usePostChangeLanguage(
  mutateFn?: UseMutationOptions<
    APIResponse<null>,
    AxiosError<APIResponse<null>>,
    PostBodyChangeLanguageInterface
  >
) {
  return useMutation<
    APIResponse<null>,
    AxiosError<APIResponse<null>>,
    PostBodyChangeLanguageInterface
  >(postChangeLanguage, mutateFn);
}

export default usePostChangeLanguage;
