import {
  SidebarMenuDefaultInterface,
  SidebarMenuInterface
} from 'uangcermat-web-toolkit-v2/build/src/blocks/Sidebar/Sidebar.type';

const COMPANY_PERMISIONS = ['pinjamCermat'];

const getCompanyPermisions = (permision: string) => {
  return permision.split('.')[0];
};

const getFeatureAccess = (permision: string) => {
  return `${permision.split('.')[1]}.${permision.split('.')[2]}`;
};

export const getGuardedNavigation = ({
  userPermission,
  navConfig
}: {
  userPermission: Array<string>;
  navConfig: Array<SidebarMenuInterface>;
}) => {
  const featurePermisions = userPermission?.map((permision) => getFeatureAccess(permision));

  return navConfig
    .map((nav) => {
      const navCompanyPermisions = getCompanyPermisions(nav.permission);
      const navAppFeatureAccess = getFeatureAccess(nav?.permission);

      const isNavHasCompanyPermisions = COMPANY_PERMISIONS?.includes(navCompanyPermisions);

      const filteredSubMenuByFeatureAccess = nav.subMenu
        ?.map((sub) => {
          const navSubMenuFeatureAccess = getFeatureAccess(sub.permission);
          const navSubMenuCompanyPermisions = getCompanyPermisions(sub.permission);

          const isSubMenuHasCompanyPermisions = COMPANY_PERMISIONS.includes(
            navSubMenuCompanyPermisions
          );

          if (
            isSubMenuHasCompanyPermisions &&
            featurePermisions?.includes(navSubMenuFeatureAccess)
          ) {
            return sub;
          }
        })
        .filter((nav) => nav !== null);

      const joinSubMenuIntoNavbar: SidebarMenuInterface = {
        ...nav,
        subMenu: filteredSubMenuByFeatureAccess as SidebarMenuDefaultInterface[]
      };

      if (isNavHasCompanyPermisions && featurePermisions.includes(navAppFeatureAccess)) {
        return joinSubMenuIntoNavbar;
      }
    })
    .filter((nav) => !!nav);
};
