import { axiosAuthInstance } from '@core/axios/axios.authConfig';
import { AxiosResponse } from 'axios';
import { PostBodyLoginInterface, ResponseLoginInterface } from 'interface/UserInterface';

export const postUserLogin = async (
  body: PostBodyLoginInterface
): Promise<ResponseLoginInterface> => {
  return axiosAuthInstance
    .post('/login', body)
    .then((res: AxiosResponse<ResponseLoginInterface>) => res.data)
    .catch((err) => err);
};
