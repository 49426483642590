export { default as BahasaCommon } from './common.json';
export { default as BahasaCooperative } from './cooperative.json';
export { default as BahasaDashboard } from './dashboard.json';
export { default as BahasaInvoice } from './invoice.json';
export { default as BahasaInvoiceDetail } from './invoiceDetail.json';
export { default as BahasaLoginPage } from './login.json';
export { default as BahasaMember } from './member.json';
export { default as BahasaMemberDetail } from './memberDetail.json';
export { default as BahasaMenu } from './menu.json';
export { default as BahasaOnboarding } from './onboarding.json';
export { default as BahasaPayroll } from './payroll.json';
