import { cn } from '@utils/tailwindMerge';
import React, { createContext, useContext } from 'react';
import {
  showToast as showToastToolkit,
  ShowToastInterface,
  Toast
} from 'uangcermat-web-toolkit-v2';

type ToastContextType = {
  showToast: (config: ShowToastInterface) => void;
};

type ToastProviderType = {
  children: React.ReactNode;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

const showToast = (config: ShowToastInterface) => {
  showToastToolkit({
    ...config,
    containerStyles: cn('w-[360px] mt-2 mr-2', config.containerStyles),
    titleStyles: cn('leading-3', config.titleStyles),
    descriptionStyles: cn('leading-[14px]', config.descriptionStyles)
  });
};

export const ToastProvider: React.FC<ToastProviderType> = ({ children }) => {
  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Toast />
    </ToastContext.Provider>
  );
};
