import { useTranslation } from 'react-i18next';
import { SidebarMenuInterface } from 'uangcermat-web-toolkit-v2';

export const useNavigationConfig = () => {
  const { t } = useTranslation(['menu']);
  const DATA_SIDEBAR: Array<SidebarMenuInterface> = [
    {
      id: 'dashboard',
      icon: 'dashboard',
      title: t('menu:dashboard.dashboard'),
      navLink: '/dashboard',
      permission: 'pinjamCermat.invoice.list',
      subMenu: [
        {
          id: 'dashboard-submenu',
          title: t('menu:dashboard.dashboard'),
          navLink: '/dashboard',
          permission: 'pinjamCermat.invoice.list'
        }
      ]
    },
    {
      id: 'payment',
      icon: 'expense',
      title: t('menu:payment.payment'),
      navLink: '/payment',
      permission: 'pinjamCermat.invoice.list',
      subMenu: [
        {
          id: 'invoice',
          title: t('menu:payment.invoices'),
          navLink: '/payment/invoice',
          permission: 'pinjamCermat.invoice.list'
        }
      ]
    }
  ];

  return { DATA_SIDEBAR };
};
