import { localStorageService } from '@core/utils/localStorage';
import {
  EnglishCommon,
  EnglishDashboard,
  EnglishInvoice,
  EnglishInvoiceDetail,
  EnglishLoginPage,
  EnglishMenu,
  EnglishOnboarding,
  EnglishPayroll
} from '@i18n/locales/en';
import {
  BahasaCommon,
  BahasaDashboard,
  BahasaInvoice,
  BahasaInvoiceDetail,
  BahasaLoginPage,
  BahasaMenu,
  BahasaOnboarding,
  BahasaPayroll
} from '@i18n/locales/id';
import { LanguageType } from '@interface/UserInterface';
// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

export const useI18n = () => {
  const lng: LanguageType = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';

  const changeLanguage = async (to: LanguageType) => {
    if (i18n.isInitialized) {
      i18n.changeLanguage(to);
      localStorageService.setLanguage({ key: 'i18nextLng', value: to });
    }
  };

  const englishResources = {
    common: EnglishCommon,
    login: EnglishLoginPage,
    menu: EnglishMenu,
    onboarding: EnglishOnboarding,
    payroll: EnglishPayroll,
    invoice: EnglishInvoice,
    dashboard: EnglishDashboard,
    invoiceDetail: EnglishInvoiceDetail
  };

  const bahasaResources = {
    common: BahasaCommon,
    login: BahasaLoginPage,
    menu: BahasaMenu,
    onboarding: BahasaOnboarding,
    payroll: BahasaPayroll,
    invoice: BahasaInvoice,
    dashboard: BahasaDashboard,
    invoiceDetail: BahasaInvoiceDetail
  };

  const resources = {
    en: englishResources,
    id: bahasaResources
  };

  const config = {
    resources,
    lng,
    ns: ['common', 'login', 'menu', 'onboarding', 'payroll', 'dashboard']
  };
  const init = () => {
    i18n.use(initReactI18next).init(config);
  };

  return { i18n, init, changeLanguage };
};
